import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | FrostGear Rentals Hakkında
			</title>
			<meta name={"description"} content={"FrostGear Rentals tek ve net bir vizyonla kuruldu: kar tutkunlarına dağ maceraları için en yüksek kalitede ekipman sağlamak. "} />
			<meta property={"og:title"} content={"Hakkımızda | FrostGear Rentals Hakkında"} />
			<meta property={"og:description"} content={"FrostGear Rentals tek ve net bir vizyonla kuruldu: kar tutkunlarına dağ maceraları için en yüksek kalitede ekipman sağlamak. "} />
			<meta property={"og:image"} content={"https://forigmastate.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://forigmastate.com/img/ski.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://forigmastate.com/img/ski.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://forigmastate.com/img/ski.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://forigmastate.com/img/ski.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://forigmastate.com/img/ski.png"} />
			<meta name={"msapplication-TileImage"} content={"https://forigmastate.com/img/ski.png"} />
			<meta name={"msapplication-TileColor"} content={"https://forigmastate.com/img/ski.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Hakkımızda
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				FrostGear Rentals tek ve net bir vizyonla kuruldu: kar tutkunlarına dağ maceraları için en yüksek kalitede ekipman sağlamak. Yolculuğumuz, köklü bir kış sporları tutkusu ve bu heyecan verici deneyimleri herkes için erişilebilir hale getirme arzusuyla başladı.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://forigmastate.com/img/a1.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://forigmastate.com/img/a2.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://forigmastate.com/img/a3.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://forigmastate.com/img/a4.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Mükemmellik Taahhüdümüz
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					Doğru ekipmanın iyi bir kar deneyimini harika bir deneyime dönüştürebileceğine inanıyoruz. Bu inanç, özenle seçilmiş ekipmanlarımızdan özel müşteri hizmetlerimize kadar FrostGear Rentals'ın her yönünü yönlendirir.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					FrostGear Rentals'ın Temel Özellikleri
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Kış Sporları Meraklıları için Bir Cennet - Kayaklar, snowboardlar ve kar motosikletleri de dahil olmak üzere ekipman yelpazemiz hem acemilere hem de deneyimli maceracılara hitap etmektedir.
Uzmanlık ve Rehberlik - Ekibimiz sadece ekipmanlarımız hakkında bilgili değil, kendileri de meraklılar, deneyim ve tutkudan doğan içgörü ve tavsiyeler sunuyorlar.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Bir Kiralama Mağazasından Daha Fazlası
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Eğitim ve Destek - Yamaçlarda güvenli ve keyifli bir deneyim sağlamak için becerileri geliştirmenin yanı sıra güven oluşturmaya yönelik eğitim seansları sunuyoruz.
Kalite ve Güvenlik - Müşterilerimizin güvenliği ve memnuniyeti her şeyden önemlidir. Ekipmanlarımızı en yüksek standartlarda titizlikle koruyor ve güncelliyoruz.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Bize Ulaşın
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://forigmastate.com/img/a5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});